import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VorlagenkonfigurationModel } from '@dworkflow/shared/model/vorlagenkonfiguration.model';
import { guid } from '@dworkflow/shared/utility/guid';
import lodash from 'lodash';
import { Observable, forkJoin, of } from 'rxjs';
import {
  catchError,
  delay,
  map,
  repeat,
  startWith,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { VorlagenkonfigurationsschrittFromDokumenteApiModel } from '../model/vorlagenkonfigurationsschritt-from-dokumente-api.model';
import { VorlagenkonfigurationsschrittFromSchritteApiModel } from '../model/vorlagenkonfigurationsschritt-from-schritte-api.model';
import { ZusaetzlicheEigenschaftenUnionType } from '../model/zusaetzliche-eigenschaften-union-type';

@Injectable({
  providedIn: 'root',
})
export class KonfigurationService {
  constructor(private http: HttpClient) {}

  createVorlagenkonfigurationen(
    value: VorlagenkonfigurationModel
  ): Observable<VorlagenkonfigurationModel> {
    return this.http
      .post<VorlagenkonfigurationModel>(environment.engine.konfigurationenEndpoint, value)
      .pipe(
        // Die Antwort beinhaltet die neue LaufendeKonfigId, aber dafür nicht mehr alle Schrittinformationen
        // daher müssen wir den value mit der API Response zusammenführen
        map(newValue => lodash.merge({}, value, newValue)),

        // Die SchrittKonfigurationen in dWorkflow speichern
        switchMap(vk => this.speichereSchrittkonfigurationen(vk).pipe(map(() => vk))),
        // Die DokumentenKonfigurationen in dWorkflow speichern
        switchMap(vk => this.speichereDokumentenkonfiguration(vk).pipe(map(() => vk))),
        // Die FormularKonfigurationen in dWorkflow speichern
        switchMap(vk => this.speichereFormularkonfiguration(vk).pipe(map(() => vk)))
      );
  }

  deleteVorlagenkonfigurationen(laufendeKonfigurationsId: number): Observable<unknown> {
    return this.http.delete(
      `${environment.engine.konfigurationenEndpoint}/${laufendeKonfigurationsId}`
    );
  }

  getVorlagenkonfigurationen(): Observable<VorlagenkonfigurationModel[]> {
    return this.http
      .get<VorlagenkonfigurationModel[]>(environment.engine.konfigurationenEndpoint)
      .pipe(
        switchMap(vks => {
          if (vks.length === 0) {
            return of([] as VorlagenkonfigurationModel[]);
          }
          return this.loadSchrittKonfigurationenIntoKonfigurationen(vks);
        })
      );
  }

  getVorlagenkonfigurationFuerVorlage(vorlagenId: string): Observable<VorlagenkonfigurationModel> {
    return this.http
      .get<VorlagenkonfigurationModel>(
        environment.engine.konfigurationenEndpoint + '/vorlage/' + vorlagenId
      )
      .pipe(
        switchMap(vk => this.loadSchrittKonfigurationIntoKonfiguration(vk)),
        catchError(e => {
          if (e instanceof HttpErrorResponse && e.status === 404) {
            return of(undefined as VorlagenkonfigurationModel);
          } else {
            throw e;
          }
        })
      );
  }

  getVorlagenkonfiguration(
    laufendeKonfigurationsId: number
  ): Observable<VorlagenkonfigurationModel> {
    return this.http
      .get<VorlagenkonfigurationModel>(
        `${environment.engine.konfigurationenEndpoint}/${laufendeKonfigurationsId}`
      )
      .pipe(switchMap(vk => this.loadSchrittKonfigurationIntoKonfiguration(vk)));
  }

  private speichereSchrittkonfigurationen(value: VorlagenkonfigurationModel): Observable<unknown> {
    const schritte = lodash.flatten(value.phasen.map(x => x.schritte));
    const payload = schritte.map(schritt => ({
      ...schritt,
      konfigurationsId: value.laufendeKonfigurationsId,
      zusaetzlicheEigenschaften: JSON.stringify(schritt.zusaetzlicheEigenschaften),
    }));
    return this.http.post(
      `${environment.schritte.schrittEndpoint}/schrittkonfigurationen`,
      payload
    );
  }

  private speichereDokumentenkonfiguration(value: VorlagenkonfigurationModel): Observable<unknown> {
    const schritte = lodash.flatten(value.phasen.map(x => x.schritte));
    const payload = schritte.map(schritt => ({
      konfigurationsId: value.laufendeKonfigurationsId,
      schrittKonfigurationId: schritt.schrittKonfigurationsId,
      isDokumentenbearbeitungErlaubt: schritt.isDokumentenbearbeitungErlaubt ?? false,
      isWorkflowFormularBearbeitungErlaubt: schritt.isWorkflowFormularBearbeitungErlaubt,
      zusaetzlicheEigenschaften: JSON.stringify(schritt.zusaetzlicheEigenschaftenFuerDokumente),
    }));
    return this.http.post(
      `${environment.dokumente.konfigurationEndpoint}/schrittkonfigurationen`,
      payload
    );
  }

  private speichereFormularkonfiguration(value: VorlagenkonfigurationModel): Observable<unknown> {
    return this.http.post(
      `${environment.dokumente.formularEndpoint}/vorlagenkonfiguration/${value.laufendeKonfigurationsId}`,
      value.formularIds
    );
  }

  public loadSchrittKonfigurationIntoKonfiguration(
    vk: VorlagenkonfigurationModel
  ): Observable<VorlagenkonfigurationModel> {
    const schrittCount = vk.phasen.flatMap(p => p.schritte).length;
    let schrittDelay = 10;
    let dokumenteDelay = 10;
    // Bei Erstellung solange pollen bis alle Konfigs geladen werden konnten
    return forkJoin([
      this.getSchrittKonfigurationenByVk(vk.laufendeKonfigurationsId).pipe(
        delay(schrittDelay),
        tap(_ => (schrittDelay = 1000)),
        repeat(5),
        takeWhile(schrittKonfigs => schrittKonfigs?.length < schrittCount, true),
        startWith([] as VorlagenkonfigurationsschrittFromSchritteApiModel[])
      ),
      this.getDokumenteSchrittKonfigurationenByVk(vk.laufendeKonfigurationsId).pipe(
        delay(dokumenteDelay),
        tap(_ => (dokumenteDelay = 1000)),
        repeat(5),
        takeWhile(schrittKonfigs => schrittKonfigs?.length < schrittCount, true),
        startWith([] as VorlagenkonfigurationsschrittFromDokumenteApiModel[])
      ),
      this.getFormulareForVorlagenkonfiguration([vk.laufendeKonfigurationsId]).pipe(
        delay(dokumenteDelay),
        tap(_ => (dokumenteDelay = 1000)),
        repeat(5)
      ),
    ]).pipe(
      map(([schritteKonfigs, dokumentenSchritteKonfigs, formularIds]) => {
        schritteKonfigs = schritteKonfigs ?? [];
        dokumentenSchritteKonfigs = dokumentenSchritteKonfigs ?? [];
        vk.formularIds = formularIds.map(f => f.formularId) ?? [];
        // Mapping von schrittKonfigurationsId => schrittKonfiguration
        const schritteKonfigDict = schritteKonfigs.reduce(
          (acc, schritt) => ({
            ...acc,
            [schritt.schrittKonfigurationsId]: schritt,
          }),
          <{ [id: string]: VorlagenkonfigurationsschrittFromSchritteApiModel }>{}
        );
        const dokumenteSchrittKonfigDict = dokumentenSchritteKonfigs.reduce(
          (acc, schritt) => ({
            ...acc,
            // Wegen einem Typo im Model muss hier ein any-access verwendet werden
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
            [schritt.schrittKonfigurationId]: schritt,
          }),
          <{ [id: string]: VorlagenkonfigurationsschrittFromDokumenteApiModel }>{}
        );

        // Patch die Vorlagenkonfigurationsschritte mit Daten aus dem Schritte und Dokumente Endpoint
        const vkSchritte = lodash.flatten(vk.phasen.map(p => p.schritte));
        for (const vkSchritt of vkSchritte) {
          const schrittKonfig = schritteKonfigDict[vkSchritt.schrittKonfigurationsId];
          if (schrittKonfig) {
            vkSchritt.allowOverrideNextSchrittverantwortlicher =
              schrittKonfig.allowOverrideNextSchrittverantwortlicher;
            vkSchritt.isRueckfrageErlaubt = schrittKonfig.isRueckfrageErlaubt;
            vkSchritt.isSichtbar = schrittKonfig.isSichtbar;
            vkSchritt.titel = schrittKonfig.titel;
            vkSchritt.schrittKonfigurationsIdFuerRueckfrage =
              schrittKonfig.schrittKonfigurationsIdFuerRueckfrage;
            vkSchritt.erlaeuterungstext = schrittKonfig.erlaeuterungstext;
            vkSchritt.schritttyp = schrittKonfig.schritttyp;
            vkSchritt.zusaetzlicheEigenschaften = JSON.parse(
              schrittKonfig.zusaetzlicheEigenschaften
            ) as ZusaetzlicheEigenschaftenUnionType;
          } else {
            vk.schrittKonfigUnvollstaendig = true;
          }

          const dokumenteSchrittKonfig =
            dokumenteSchrittKonfigDict[vkSchritt.schrittKonfigurationsId];
          if (dokumenteSchrittKonfig) {
            vkSchritt.isDokumentenbearbeitungErlaubt =
              dokumenteSchrittKonfig.isDokumentenbearbeitungErlaubt;
            vkSchritt.isWorkflowFormularBearbeitungErlaubt =
              dokumenteSchrittKonfig.isWorkflowFormularBearbeitungErlaubt;
            vkSchritt.zusaetzlicheEigenschaftenFuerDokumente = JSON.parse(
              dokumenteSchrittKonfig.zusaetzlicheEigenschaften
            ) as ZusaetzlicheEigenschaftenUnionType;
          } else {
            vk.schrittKonfigUnvollstaendig = true;
          }
        }

        return vk;
      })
    );
  }

  public loadSchrittKonfigurationenIntoKonfigurationen(
    vks: VorlagenkonfigurationModel[]
  ): Observable<VorlagenkonfigurationModel[]> {
    const schrittCount = vks.flatMap(vk => vk.phasen.flatMap(p => p.schritte)).length;
    const vksIds = vks.map(v => v.laufendeKonfigurationsId);
    let schrittDelay = 10;
    let dokumenteDelay = 10;
    // Bei Erstellung solange pollen bis alle Konfigs geladen werden konnten
    return forkJoin([
      this.getSchrittKonfigurationenByVks(vksIds).pipe(
        delay(schrittDelay),
        tap(_ => (schrittDelay = 1000)),
        repeat(5),
        takeWhile(schrittKonfigs => schrittKonfigs?.length < schrittCount, true),
        startWith([] as VorlagenkonfigurationsschrittFromSchritteApiModel[])
      ),
      this.getDokumenteSchrittKonfigurationenByVks(vksIds).pipe(
        delay(dokumenteDelay),
        tap(_ => (dokumenteDelay = 1000)),
        repeat(5),
        takeWhile(schrittKonfigs => schrittKonfigs?.length < schrittCount, true),
        startWith([] as VorlagenkonfigurationsschrittFromDokumenteApiModel[])
      ),
      this.getFormulareForVorlagenkonfiguration(vksIds).pipe(
        delay(dokumenteDelay),
        tap(_ => (dokumenteDelay = 1000)),
        repeat(5)
      ),
    ]).pipe(
      map(([schritteKonfigs, dokumentenSchritteKonfigs, formularIds]) => {
        schritteKonfigs = schritteKonfigs ?? [];
        dokumentenSchritteKonfigs = dokumentenSchritteKonfigs ?? [];
        formularIds = formularIds ?? [];
        // Mapping von schrittKonfigurationsId => schrittKonfiguration
        const schritteKonfigDict = schritteKonfigs.reduce(
          (acc, schritt) => ({
            ...acc,
            [schritt.schrittKonfigurationsId]: schritt,
          }),
          <{ [id: string]: VorlagenkonfigurationsschrittFromSchritteApiModel }>{}
        );
        const dokumenteSchrittKonfigDict = dokumentenSchritteKonfigs.reduce(
          (acc, schritt) => ({
            ...acc,
            // Wegen einem Typo im Model muss hier ein any-access verwendet werden
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
            [schritt.schrittKonfigurationId]: schritt,
          }),
          <{ [id: string]: VorlagenkonfigurationsschrittFromDokumenteApiModel }>{}
        );
        // Patch die Vorlagenkonfigurationsschritte mit Daten aus dem Schritte und Dokumente Endpoint
        for (const vk of vks) {
          vk.formularIds =
            formularIds
              .filter(fi => fi.vorlagenKonfigurationId === vk.laufendeKonfigurationsId)
              .map(fi => fi.formularId) ?? [];
          const vkSchritte = lodash.flatten(vk.phasen.map(p => p.schritte));
          for (const vkSchritt of vkSchritte) {
            const schrittKonfig = schritteKonfigDict[vkSchritt.schrittKonfigurationsId];
            if (schrittKonfig) {
              vkSchritt.allowOverrideNextSchrittverantwortlicher =
                schrittKonfig.allowOverrideNextSchrittverantwortlicher;
              vkSchritt.isRueckfrageErlaubt = schrittKonfig.isRueckfrageErlaubt;
              vkSchritt.isSichtbar = schrittKonfig.isSichtbar;
              vkSchritt.titel = schrittKonfig.titel;
              vkSchritt.schritttyp = schrittKonfig.schritttyp;
              vkSchritt.schrittKonfigurationsIdFuerRueckfrage =
                schrittKonfig.schrittKonfigurationsIdFuerRueckfrage;
              vkSchritt.erlaeuterungstext = schrittKonfig.erlaeuterungstext;
              vkSchritt.zusaetzlicheEigenschaften = JSON.parse(
                schrittKonfig.zusaetzlicheEigenschaften
              ) as ZusaetzlicheEigenschaftenUnionType;
            } else {
              vk.schrittKonfigUnvollstaendig = true;
            }

            const dokumenteSchrittKonfig =
              dokumenteSchrittKonfigDict[vkSchritt.schrittKonfigurationsId];
            if (dokumenteSchrittKonfig) {
              vkSchritt.isDokumentenbearbeitungErlaubt =
                dokumenteSchrittKonfig.isDokumentenbearbeitungErlaubt;
              vkSchritt.isWorkflowFormularBearbeitungErlaubt =
                dokumenteSchrittKonfig.isWorkflowFormularBearbeitungErlaubt;
              vkSchritt.zusaetzlicheEigenschaftenFuerDokumente = JSON.parse(
                dokumenteSchrittKonfig.zusaetzlicheEigenschaften
              ) as ZusaetzlicheEigenschaftenUnionType;
            } else {
              vk.schrittKonfigUnvollstaendig = true;
            }
          }
        }

        return vks;
      })
    );
  }

  private getSchrittKonfigurationenByVk(
    laufendeKonfigurationsId: number
  ): Observable<VorlagenkonfigurationsschrittFromSchritteApiModel[]> {
    return this.http.get<VorlagenkonfigurationsschrittFromSchritteApiModel[]>(
      `${environment.schritte.schrittEndpoint}/schrittkonfigurationen/${laufendeKonfigurationsId}`
    );
  }

  private getSchrittKonfigurationenByVks(
    laufendeKonfigurationsIds: number[]
  ): Observable<VorlagenkonfigurationsschrittFromSchritteApiModel[]> {
    return this.http.post<VorlagenkonfigurationsschrittFromSchritteApiModel[]>(
      `${environment.schritte.schrittEndpoint}/getschrittkonfigurationen`,
      laufendeKonfigurationsIds
    );
  }

  private getDokumenteSchrittKonfigurationenByVk(
    laufendeKonfigurationsId: number
  ): Observable<VorlagenkonfigurationsschrittFromDokumenteApiModel[]> {
    return this.http.get<VorlagenkonfigurationsschrittFromDokumenteApiModel[]>(
      `${environment.dokumente.konfigurationEndpoint}/${laufendeKonfigurationsId}`
    );
  }

  private getDokumenteSchrittKonfigurationenByVks(
    laufendeKonfigurationsIds: number[]
  ): Observable<VorlagenkonfigurationsschrittFromDokumenteApiModel[]> {
    return this.http.post<VorlagenkonfigurationsschrittFromDokumenteApiModel[]>(
      `${environment.dokumente.konfigurationEndpoint}/getschrittkonfigurationen`,
      laufendeKonfigurationsIds
    );
  }

  private getFormulareForVorlagenkonfiguration(
    laufendeKonfigurationsIds: number[]
  ): Observable<{ vorlagenKonfigurationId: number; formularId: number }[]> {
    return this.http.post<{ vorlagenKonfigurationId: number; formularId: number }[]>(
      `${environment.dokumente.formularEndpoint}/vorlagenkonfiguration`,
      laufendeKonfigurationsIds
    );
  }

  konfigurationHasVorlagen(id: guid): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.engine.konfigurationenEndpoint}/${id}/has-vorlagen`
    );
  }

  getAnzahlSichtbarerKonfigurationen(): Observable<number> {
    return this.http.get<number>(`${environment.engine.konfigurationenEndpoint}/anzahl-sichtbare`);
  }
}
